import React, { Component } from 'react'
import Layout from '../components/layout/Layout'

import AnalysePeinture from '../components/AnalysePeinture'

class App extends Component {
  render() {
    return (
      <>
        <Layout backgroundImage>
          <AnalysePeinture />
        </Layout>
      </>
    )
  }
}

export default App
