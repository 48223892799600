import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-awesome-reveal'

const AnalysePeinture = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "aquarelles/aquarelles_12.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 79) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const postStyle = {
    color: 'black',
    backgroundColor: 'white',
    fontSize: 'calc(1rem + 0.5vw)',
    justifyContent: 'center',
    borderRadius: '25px',
    textAlign: 'justify',
  }

  return (
    <Container className="px-5 py-3 my-5 " style={postStyle}>
      <Fade>
        <Row>
          <Col>
            <h1 className="text-center my-5" style={{ color: '#f0ad4e' }}>
              Analyse de la peinture de Léon HAMONET
            </h1>
          </Col>
        </Row>
        <p>
          L’art graphique de Léon Hamonet exprimait dès ses débuts de jeune
          peintre, une vision très personelle : ses études de paysages et de
          personnages intègrent le cadrage découpage, proche de la photographie,
          et l’artiste recherche un dessin synthétique où les formes et les
          plans sont clairement définis. Les croquis de mer, avec ses vagues
          stylisées, proches des estampes japonaises, n’évoquent-elles pas les
          artistes de l’école de Pont Aven ?{' '}
        </p>
        <p>
          Léon Hamonet se préserve de l’anecdotique et du régionalisme pour
          mieux traduire les contrastes de climat, le caractère du sujet
          dessiné. Dans ses plus infimes croquis, il déploie un métier sûr où
          l’observation laisse libre cours à son imaginaire. 
        </p>
        <Row>
        <Col>
          <p style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>Franck Accart</span>
            <br />
            <span style={{ fontStyle: 'italic' }}>Expert de la chambre européenne des experts d’art </span>
          </p>
          </Col>
        </Row>
        <Img
          fluid={data.image1.childImageSharp.fluid}
          alt="test"
          className="my-3"
        />
      </Fade>
    </Container>
  )
}

export default AnalysePeinture
